var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$isEnabledProperty(_vm.$LISTADO_CTA_CTE))?_c('v-container',{attrs:{"id":"crud","fluid":"","tag":"section"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Consulta de "+_vm._s(_vm.title))])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processedData,"search":_vm.search,"sort-by":"created_at","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pr-2 mt-6",attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',{staticClass:"pt-20",attrs:{"xs":"12","sm":"10","md":"8"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":'Búsqueda por Ente',"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pt-28 text-right",attrs:{"xs":"12","sm":"2","md":"4"}},[_c('v-btn',{staticClass:"btn__rounded",attrs:{"color":"secondary"}},[_c('download-excel',{attrs:{"fields":_vm.json_fields,"fetch":_vm.fetchData,"type":_vm.dataExportType,"name":_vm.title +
                    '-' +
                    _vm.$formatDate(_vm.nowDate) +
                    '-' +
                    _vm.$getDate() +
                    '.' +
                    _vm.dataExportType}},[_c('v-icon',{staticClass:"white--text",attrs:{"left":""}},[_vm._v("mdi-file-excel-box-outline")]),_vm._v(" Exportar Excel ")],1)],1)],1)],1)],1)]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","color":_vm.$cv('btnActivo'),"title":"Ver Comprobante"},on:{"click":function($event){return _vm.alert('Código en proceso', 'Funcionalidad sin terminar')}}},[_vm._v(" mdi-file-document ")])]}},{key:"item.debe",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$formatCurrency(item.debe))+" ")]}},{key:"item.haber",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$formatCurrency(item.haber))+" ")]}},{key:"item.saldo",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$formatCurrency(item.saldo))+" ")]}}],null,true)})],1),_c('v-btn',{staticClass:"btn__rounded btn__margin",attrs:{"color":_vm.$cv('principal')},on:{"click":function($event){return _vm.$goBack()}}},[_c('v-icon',{staticClass:"px-1",attrs:{"color":"white"}},[_vm._v("mdi-arrow-left-thin")]),_vm._v(" Atrás ")],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }